import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import classNames from 'classnames'

import { Spin } from 'antd'
import Dropzone from 'react-dropzone'
import { Upload } from 'react-feather'
import swal from '@sweetalert/with-react'

interface UploadButtonProps {
  className?: string
  onUpload: (file: File) => void
}

const UploadButton: React.SFC<UploadButtonProps> = ({
  className,
  onUpload,
}) => {
  const [processingFile, setProcessingFile] = useState(false)

  const handleUpload = (acceptedFiles: File[]) => {
    if (isEmpty(acceptedFiles)) {
      swal({
        icon: 'error',
        text: 'Error processing file! Please ensure your file is a CSV.',
      })
      return
    }

    setProcessingFile(true)
    onUpload(acceptedFiles[0])
  }

  return (
    <Dropzone onDrop={handleUpload} multiple={false}>
      {({ getRootProps, getInputProps }) => (
        <div
          className={classNames(
            'cursor-pointer',
            'rounded',
            'border-2',
            'border-gray-400',
            'bg-gray-300',
            'my-2',
            'p-4',
            'text-center',
            'text-gray-600',
            'w-1/3',
            className
          )}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {processingFile ? (
            <>
              <Spin className="mx-auto" />
              <p>Loading file...</p>
            </>
          ) : (
            <>
              <Upload className="mx-auto" />
              <p>Drag and drop or click to select</p>
            </>
          )}
        </div>
      )}
    </Dropzone>
  )
}

export default UploadButton
