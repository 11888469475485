import React from 'react'
import { Button, Table } from 'antd'
import WizardStore from 'src/stores/WizardStore'

const WelcomeView: React.SFC<{}> = () => {
  const SAMPLE_PARTICIPANT_COLUMNS = [
    { title: 'email', dataIndex: 'email', key: 'email' },
    { title: 'rankings', dataIndex: 'rankings', key: 'rankings' },
    { title: 'do not want', dataIndex: 'dnw', key: 'dnw' },
  ]

  const SAMPLE_PARTICIPANT_ROWS: Record<string, string>[] = [
    { email: 'a@example.com', rankings: '1, 2, 3', dnw: '' },
    { email: 'b@example.com', rankings: '3, 2', dnw: '1' },
    { email: 'c@example.com', rankings: '1,2', dnw: '' },
  ]

  const SAMPLE_OPTION_COLUMNS = [
    { title: 'number', dataIndex: 'id', key: 'id' },
  ]

  const SAMPLE_OPTION_ROWS: Record<string, string>[] = [
    { id: '1' },
    { id: '2' },
    { id: '3' },
  ]

  return (
    <div>
      <h2 className="text-3xl">Getting Started</h2>
      <p className="mt-3">
        All you need are 2 spreadsheets: one that has all the{' '}
        <strong>options</strong> that participants can choose from, and one that
        has all the <strong>participants' matching preferences</strong>.
      </p>

      <p className="mt-3">
        These can be in any format! The only requirements are that
      </p>
      <ul className="ml-8 list-disc">
        <li>
          The <strong>participants sheet</strong> has a column that uniquely
          identifies each participant (ex. email)
        </li>
        <li>
          The <strong>participants sheet</strong> has a column that has a
          comma-separated list of options they want in ranked order (ex. 1, 2,
          3)—we recommend setting a minimum number of options to have here (ex.
          everyone must rank at least 5) and verifying correctness as claims
          come in
        </li>
        <li>
          The <strong>participants sheet</strong> having a column that has a
          comma-separated list of options they DON'T want (ex. 4, 5, 6) — this
          can be used to account for participants who don't want to work with
          each other
        </li>
        <li>
          The <strong>options sheet</strong> has a column that relates each
          option to whatever it is the participants ranked (so if participants
          ranked 1, 2, 3, there should be a column in options where one row has
          1, another has 2, etc.)
        </li>
      </ul>

      <p className="mt-3">Matchmaker also supports</p>
      <ul className="ml-8 list-disc">
        <li>
          The <strong>participants sheet</strong> having a column that indicates
          whether or not they're willing to be matched with an option that's not
          in their top choices AND not in their do not wants (in this case you
          would want to allow your participants to list explicit do not wants)
        </li>
        <li>
          The <strong>participants sheet</strong> having a column that indicates
          whether or not they're willing to DOUBLE UP and receive more than one
          option if need be
        </li>
      </ul>

      <p className="mt-3">Some notes:</p>
      <ul className="ml-8 list-disc">
        <li>
          If an <strong>option</strong> doesn't want to work with a particular
          participant, add them to that participant's do not want list
        </li>
      </ul>

      <h2 className="mt-4 text-2xl">Minimum Example Sheets</h2>
      <p>
        Your sheets can contain much more information than this! These sheets
        just show the minimum required.
      </p>

      <h3 className="mt-3 text-xl">Example Participant Sheet</h3>
      <Table
        dataSource={SAMPLE_PARTICIPANT_ROWS}
        columns={SAMPLE_PARTICIPANT_COLUMNS}
        rowKey="email"
        pagination={false}
        size="small"
      />

      <h3 className="mt-3 text-xl mt-4">Example Option Sheet</h3>
      <Table
        dataSource={SAMPLE_OPTION_ROWS}
        columns={SAMPLE_OPTION_COLUMNS}
        rowKey="id"
        pagination={false}
        size="small"
      />

      <Button className="mt-4" type="primary" onClick={WizardStore.stepNext}>
        Let's get started!
      </Button>
    </div>
  )
}

export default WelcomeView
