import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Formik } from 'formik'
import * as yup from 'yup'
import classNames from 'classnames'
import {
  isEmpty,
  isUndefined,
  keys,
  omit,
  uniq,
  values as getValues,
} from 'lodash'

import { Button, Checkbox, Form, Input, Select, Switch } from 'antd'
import { ArrowLeft } from 'react-feather'
import Dropzone from 'react-dropzone'
import UploadButton from 'src/components/UploadButton'
import swal from '@sweetalert/with-react'

import Papa from 'papaparse'
import MatchmakerStore from 'src/stores/MatchmakerStore'
import WizardStore from 'src/stores/WizardStore'

interface PapaParseResults {
  data: Record<string, any>[]
  meta: {
    fields: string[]
  }
}

const UploadClaimsSchema = yup.object().shape({
  idField: yup.string().required(),
  optionRankingsField: yup.string().required(),
  doNotWantField: yup.string().required(),
  canMatchUnrankedField: yup.string(),
  canMatchUnrankedYes: yup.string().when('canMatchUnrankedField', {
    is: (field) => !isEmpty(field),
    then: yup.string().required(),
  }),
  canDoubleField: yup.string(),
  canDoubleYes: yup.string().when('canDoubleField', {
    is: (field) => !isEmpty(field),
    then: yup.string().required(),
  }),
  columns: yup.array(yup.string()),
  multipleRounds: yup.boolean(),
})

const UploadClaimsView: React.SFC<{}> = observer(() => {
  // parsed data
  const [claimsData, setClaimsData] = useState<
    Record<string, any>[] | undefined
  >(undefined)
  const [claimsFields, setClaimsFields] = useState<string[] | undefined>(
    undefined
  )

  const onUpload = (file: File) => {
    const data = Papa.parse(file, {
      header: true,
      skipEmptyLines: 'greedy',
      complete: (results: PapaParseResults, file: File) => {
        setClaimsData(results.data)
        setClaimsFields(results.meta.fields)
      },
      error: (error: Error, file: File) => {
        console.log(error)

        swal({
          icon: 'error',
          text: `Error processing the file! Please ensure your file is a CSV.\n${error}`,
        })
      },
    })
  }

  if (isUndefined(claimsData) || isUndefined(claimsFields)) {
    return (
      <>
        <Button onClick={WizardStore.stepPrevious}>← Redo options</Button>

        <p className="mt-6">
          Upload a CSV of the{' '}
          <strong>participants and their matching preferences</strong>. (These
          would be the artists in a traditional Big Bang.)
        </p>
        <p>
          <strong>This must be a CSV file with headers.</strong>
        </p>
        <p className="py-2">
          (If using Google Sheets, you can download each of your sheets by
          clicking{' '}
          <strong>
            File > Download > Comma-separated values (.csv, current sheet)
          </strong>{' '}
          to use them on matchmaker.)
        </p>
        <UploadButton onUpload={onUpload} />
      </>
    )
  }

  const saveClaims = (values: Record<string, any>) => {
    // save config and data to matchmaker store
    MatchmakerStore.setClaimantIdColumn(values.idField!)
    MatchmakerStore.setClaimantColumns(
      values.columns.sort((a: string, b: string) => {
        const aIndex = claimsFields!.indexOf(a)
        const bIndex = claimsFields!.indexOf(b)

        return aIndex < bIndex ? -1 : aIndex === bIndex ? 0 : 1
      })
    )
    MatchmakerStore.setMultipleRounds(values.multipleRounds)
    MatchmakerStore.setClaimantsData(
      claimsData!.map((claimant) => ({
        id: claimant[values.idField!],
        optionRankings: claimant[values.optionRankingsField]
          .split(',')
          .map((option: string) => option.trim()),
        doNotWant: claimant[values.doNotWantField]
          .split(',')
          .map((option: string) => option.trim()),
        canMatchUnranked: isEmpty(values.canMatchUnrankedField)
          ? false
          : claimant[values.canMatchUnrankedField] ===
            values.canMatchUnrankedYes,
        canDouble: isEmpty(values.canDoubleField)
          ? false
          : claimant[values.canDoubleField] === values.canDoubleYes,
        meta: claimant,
      }))
    )

    // move on with the wizard
    WizardStore.stepNext()
  }

  return (
    <Formik
      initialValues={{
        idField: '',
        optionRankingsField: '',
        doNotWantField: '',
        canMatchUnrankedField: '',
        canMatchUnrankedYes: '',
        canDoubleField: '',
        canDoubleYes: '',
        columns: [] as string[],
        multipleRounds: true,
      }}
      onSubmit={saveClaims}
      validationSchema={UploadClaimsSchema}
    >
      {({
        dirty,
        handleChange,
        handleReset,
        handleSubmit,
        isValid,
        isSubmitting,
        setFieldValue,
        values,
      }) => {
        // don't allow selecting the same column multiple times
        const usedFields = new Set(getValues(omit(values, ['columns'])))
        const availableFields = claimsFields.filter(
          (field) => !usedFields.has(field)
        )

        const selectIdField = (field: string) => {
          const newColumns = values.columns.filter(
            (column) => column !== values.idField
          )

          setFieldValue('idField', field)
          if (!values.columns.includes(field)) {
            setFieldValue('columns', [...newColumns, field])
          }
        }

        // potential values for selected columns
        const uniqueValues = (field: string) =>
          uniq(claimsData.map((claim) => claim[field])).sort()

        const canMatchUnrankedValues = isEmpty(values.canMatchUnrankedField)
          ? []
          : uniqueValues(values.canMatchUnrankedField)

        const canDoubleValues = isEmpty(values.canDoubleField)
          ? []
          : uniqueValues(values.canDoubleField)

        return (
          <>
            <Button
              onClick={() => {
                setClaimsData(undefined)
                setClaimsFields(undefined)
                handleReset()
              }}
            >
              ← Upload a different file
            </Button>

            <Form className="mt-8" layout="vertical">
              <Form.Item
                label={
                  <span>
                    Which column can <strong>uniquely identify</strong> this
                    participant? (ex. email)
                  </span>
                }
              >
                <Select value={values.idField} onChange={selectIdField}>
                  {availableFields.map((field) => (
                    <Select.Option key={field} value={field}>
                      {field}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <>
                    <p>
                      Which column contains the participants'{' '}
                      <strong>ranked top choices</strong>?
                      <br />
                      (REMINDER: This column must have a comma-separated list of
                      values from the{' '}
                      <strong>
                        {MatchmakerStore.config.optionIdColumn}
                      </strong>{' '}
                      column in the options spreadsheet. First choices should
                      come first. Spaces or no spaces are both okay.)
                    </p>
                  </>
                }
              >
                <Select
                  value={values.optionRankingsField}
                  onChange={(value: string) =>
                    setFieldValue('optionRankingsField', value)
                  }
                >
                  {availableFields.map((field) => (
                    <Select.Option key={field} value={field}>
                      {field}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <>
                    <p>
                      Which column contains the list of options participants{' '}
                      <strong>do not want</strong> to be matched with?
                      <br />
                      (REMINDER: This column must have a comma-separated list of
                      values from the{' '}
                      <strong>
                        {MatchmakerStore.config.optionIdColumn}
                      </strong>{' '}
                      column in the options spreadsheet.)
                    </p>
                  </>
                }
              >
                <Select
                  value={values.doNotWantField}
                  onChange={(value: string) =>
                    setFieldValue('doNotWantField', value)
                  }
                >
                  {availableFields.map((field) => (
                    <Select.Option key={field} value={field}>
                      {field}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {MatchmakerStore.options.length !== claimsData.length && (
                <Form.Item
                  label={
                    <>
                      <p>
                        Your number of options and claimants is uneven! Do extra
                        rounds of matching to make sure everyone gets a claim?
                        <br />
                        (If off, we will leave some participants unmatched for
                        you to manually match.)
                      </p>
                    </>
                  }
                >
                  <Switch
                    onChange={(checked) =>
                      setFieldValue('multipleRounds', checked)
                    }
                    checked={values.multipleRounds}
                  />
                </Form.Item>
              )}

              {values.multipleRounds &&
                MatchmakerStore.options.length > claimsData.length && (
                  <>
                    <Form.Item
                      label={
                        <>
                          <p>
                            (OPTIONAL) You have more options than claimants!
                            Which column indicates whether or not participants
                            are willing to double up?
                            <br />
                            (If left blank, we will not double up any
                            participant.)
                          </p>
                        </>
                      }
                    >
                      <Select
                        value={values.canDoubleField}
                        onChange={(value: string) => {
                          setFieldValue('canDoubleField', value)
                          setFieldValue('canDoubleYes', '')
                        }}
                        allowClear
                      >
                        {availableFields.map((field) => (
                          <Select.Option key={field} value={field}>
                            {field}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {!isEmpty(values.canDoubleField) && (
                      <Form.Item
                        className="w-1/2 mx-auto"
                        label="What value in the column indicates Yes?"
                      >
                        <Select
                          value={values.canDoubleYes}
                          onChange={(value: string) =>
                            setFieldValue('canDoubleYes', value)
                          }
                        >
                          {canDoubleValues.map((value) => (
                            <Select.Option key={value} value={value}>
                              {value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </>
                )}

              <Form.Item
                label={
                  <>
                    <p>
                      (OPTIONAL) Which column indicates whether or not
                      participants are okay with being matched with an option
                      they didn't rank?
                      <br />
                      (If left blank, we will not match any participant with an
                      option they did not explicitly rank.)
                    </p>
                  </>
                }
              >
                <Select
                  value={values.canMatchUnrankedField}
                  onChange={(value: string) => {
                    setFieldValue('canMatchUnrankedField', value)
                    setFieldValue('canMatchUnrankedYes', '')
                  }}
                  allowClear
                >
                  {availableFields.map((field) => (
                    <Select.Option key={field} value={field}>
                      {field}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {!isEmpty(values.canMatchUnrankedField) && (
                <Form.Item
                  className="w-1/2 mx-auto"
                  label="What value in the column indicates Yes?"
                >
                  <Select
                    value={values.canMatchUnrankedYes}
                    onChange={(value: string) =>
                      setFieldValue('canMatchUnrankedYes', value)
                    }
                  >
                    {canMatchUnrankedValues.map((value) => (
                      <Select.Option key={value} value={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <Form.Item label="Which columns would you like included in the output?">
                <Checkbox.Group
                  value={values.columns}
                  onChange={(columns) => setFieldValue('columns', columns)}
                  options={claimsFields.map((option) => ({
                    label: option,
                    value: option,
                    disabled: option === values.idField,
                  }))}
                />
              </Form.Item>
            </Form>

            <Button
              type="primary"
              onClick={handleSubmit as any}
              disabled={!dirty || !isValid || isSubmitting}
            >
              Ready for matching!
            </Button>
          </>
        )
      }}
    </Formik>
  )
})

export default UploadClaimsView
