import React, { ComponentType } from 'react'
import { action, computed, observable } from 'mobx'

import WelcomeView from 'src/views/WelcomeView'
import UploadOptionsView from 'src/views/UploadOptionsView'
import UploadClaimsView from 'src/views/UploadClaimsView'
import MatchingView from 'src/views/MatchingView'

interface Step {
  number: number
  title: string
  description?: string
  component: ComponentType<any>
}

export const STEPS: Step[] = [
  { number: 1, title: 'Getting Started', component: WelcomeView },
  { number: 2, title: 'Options', component: UploadOptionsView },
  { number: 3, title: 'Claims', component: UploadClaimsView },
  { number: 4, title: 'Matching', component: MatchingView },
]

class WizardStore {
  @observable currentStepIndex = 0

  @computed get currentStep(): Step {
    // account for 0-indexing
    return STEPS[this.currentStepIndex]
  }

  @action.bound reset() {
    this.currentStepIndex = 0
  }

  @action.bound stepPrevious(): void {
    // don't go before the beginning of the list
    if (this.currentStepIndex == 0) {
      return
    }

    this.currentStepIndex = this.currentStepIndex - 1
  }

  @action.bound stepNext(): void {
    // don't go after the end of the list
    if (this.currentStepIndex == STEPS.length - 1) {
      return
    }

    this.currentStepIndex = this.currentStepIndex + 1
  }
}

export default new WizardStore()
