import { action, computed, observable } from 'mobx'
import { isUndefined } from 'lodash'
import Papa from 'papaparse'
import ky from 'ky'

interface Claimant {
  id: string
  optionRankings: string[]
  doNotWant: string[]
  canMatchUnranked: boolean
  canDouble: boolean
  meta: Record<string, string>
}

interface Option {
  id: string
  meta: Record<string, string>
}

interface Config {
  claimantColumns: string[]
  claimantIdColumn: string
  optionColumns: string[]
  optionIdColumn: string
  multipleRounds: boolean
}

interface Match {
  claimant: Record<string, string>
  option: Record<string, string>
  ranking: number | string
}

const DEFAULT_CONFIG = {
  claimantColumns: [],
  claimantIdColumn: '',
  optionColumns: [],
  optionIdColumn: '',
  multipleRounds: true,
}

class MatchmakerStore {
  @observable claimants: Claimant[] = []
  @observable options: Option[] = []
  @observable config: Config = { ...DEFAULT_CONFIG }

  @observable matches: Match[] | undefined
  @observable unmatched: Record<string, string>[] | undefined

  @computed get resultsCsv(): string {
    if (isUndefined(this.matches)) {
      return Papa.unparse([])
    }

    return Papa.unparse({
      fields: [
        ...this.config.claimantColumns,
        ...this.config.optionColumns,
        'Choice Number',
      ],
      data: this.matches.map((match) => [
        ...this.config.claimantColumns.map((column) => match.claimant[column]),
        ...this.config.optionColumns.map((column) => match.option[column]),
        match.ranking,
      ]),
    })
  }

  @action.bound reset() {
    this.claimants = []
    this.options = []
    this.config = { ...DEFAULT_CONFIG }

    this.matches = undefined
    this.unmatched = undefined
  }

  @action.bound setOptionsData(options: Option[]): void {
    this.options = options
  }

  @action.bound setOptionIdColumn(column: string): void {
    this.config.optionIdColumn = column
  }

  @action.bound setOptionColumns(columns: string[]): void {
    this.config.optionColumns = columns
  }

  @action.bound setClaimantsData(claimants: Claimant[]): void {
    this.claimants = claimants
  }

  @action.bound setClaimantIdColumn(column: string): void {
    this.config.claimantIdColumn = column
  }

  @action.bound setClaimantColumns(columns: string[]): void {
    this.config.claimantColumns = columns
  }

  @action.bound setMultipleRounds(runMultiple: boolean): void {
    this.config.multipleRounds = runMultiple
  }

  @action.bound async doMatching(): Promise<void> {
    const payload = {
      claimants: this.claimants.map((claimant) => ({
        id: claimant.id,
        option_rankings: claimant.optionRankings,
        do_not_want: claimant.doNotWant,
        can_match_unranked: claimant.canMatchUnranked,
        can_double: claimant.canDouble,
        meta: claimant.meta,
      })),
      options: this.options,
      config: {
        claimant_columns: this.config.claimantColumns,
        option_columns: this.config.optionColumns,
        multiple_rounds: this.config.multipleRounds,
      },
    }

    const response = await ky.post('/bigbang', { json: payload })
    const results = await response.json()

    this.matches = results.matches
    this.unmatched = results.unmatched
    return
  }
}

export default new MatchmakerStore()
