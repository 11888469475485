import React from 'react'
import { Steps } from 'antd'

import WizardStore, { STEPS } from 'src/stores/WizardStore'

const Progress = () => {
  return (
    <Steps
      direction="vertical"
      size="small"
      current={WizardStore.currentStepIndex}
    >
      {STEPS.map((step) => (
        <Steps.Step
          key={step.number}
          title={step.title}
          description={step.description}
        />
      ))}
    </Steps>
  )
}

export default Progress
