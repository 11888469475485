import React from 'react'
import { Modal } from 'antd'

interface FAQModalProps {
  onClose: () => void
  visible: boolean
}

const Contact: React.SFC<{}> = () => {
  return (
    <>
      <a href="mailto:hello@fandom.love">email</a> //{' '}
      <a href="https://twitter.com/fandomdotlove" target="_blank">
        twitter
      </a>{' '}
      //{' '}
      <a href="https://curiouscat.me/fandomdotlove" target="_blank">
        curiouscat
      </a>
    </>
  )
}

const FAQModal: React.SFC<FAQModalProps> = ({ onClose, visible }) => {
  return (
    <Modal
      width="75%"
      visible={visible}
      footer={null}
      centered
      onCancel={onClose}
    >
      <h1 className="text-3xl">FAQ</h1>

      <h2 className="pt-4 text-2xl">The High Level</h2>
      <div className="pl-4">
        <p className="py-2">
          <em>
            This describes a traditional Big Bang (artists claiming fic
            summaries) but it will also work for reverse bangs, or any other
            situation where matching is needed!
          </em>
        </p>
        <ul className="list-disc list-inside">
          <li>
            Artists will provide a list of top summary choices (ideally, at
            least 3), and a list of "Do Not Want".
          </li>
          <li>
            Artists will NEVER be matched with an option in their "Do Not Want"
            list.
          </li>
          <li>
            However, it is possible for artists to be matched with something NOT
            in their top choices if they agree to it.
          </li>
          <li>
            The matcher tries to give as many people their top choices as
            possible, but it's hard if a lot of people have the same top
            choices!
          </li>
        </ul>
      </div>

      <h2 className="pt-4 text-2xl">Disclaimer</h2>
      <div className="pl-4">
        <p className="py-2">Matchmaker makes the following guarantees:</p>
        <ul className="list-disc list-inside">
          <li>
            Artists will NEVER be matched with an option they have explicitly
            marked as not wanting to be matched with.
          </li>
          <li>
            If an artist has requested to ONLY be matched with a top choice,
            they will either be matched with a top choice or left unmatched.
          </li>
          <li>
            Matchmaker will make a best effort to match everyone with a top
            choice.
          </li>
          <li>
            Matchmaker will return the results to the mod(s) doing the matching
            for further action.
          </li>
        </ul>
        <p className="py-2">
          Matchmaker <strong>does not</strong> check data for correctness. That
          means Matchmaker does not check for situations such as someone both
          ranking something as a top choice and also listing it in do not want
          (the do not want will take precedence), or that people have ranked the
          minimum number of options and are not trying to game the system. It is
          still up to mods to review claims information before submitting to
          matchmaker.
        </p>

        <p className="py-2">
          Matchmaker <strong>cannot</strong> guarantee that everyone will
          receive a top choice—for an extreme example, imagine all 20
          participants only ranked the same 5 top choices. It’s impossible for
          everyone to get a top choice in that case. It's a good approach for
          mods to keep an eye on claims as they come in.
        </p>

        <p className="py-2">
          Matchmaker will make its best-effort matching and return the results
          as a CSV. Mods can use the matching results as-is, or make manual
          adjustments as they deem fit. Matchmaker is not responsible for how
          matches are used after they’ve been generated.
        </p>
      </div>

      <h2 className="pt-4 text-2xl">About Matchmaker</h2>
      <h3 className="pt-2 text-xl">How do I know this works?</h3>
      <div className="pl-4">
        <p className="py-2">
          Matchmaker is free to use! Feel free to upload test spreadsheets and
          check out the results for yourself. Here are some example spreadsheets
          showing fake artists, writers, and real matching results to get you
          started:
        </p>
        <ul className="list-disc list-inside">
          <li>
            <a
              href="https://docs.google.com/spreadsheets/d/1QOO_XmkYzrkHNNiiXQEgP4B6T6WzymwA0652tGi_JZ8/edit?usp=sharing"
              target="_blank"
              noopener noreferrer
            >
              More artists than writers
            </a>
          </li>
          <li>
            <a
              href="https://docs.google.com/spreadsheets/d/1nJOZQO8J8sQuAm18gHl86P-INbp9S3Um5O1W8qnOHSs/edit?usp=sharing"
              target="_blank"
              noopener noreferrer
            >
              More writers than artists
            </a>
          </li>
        </ul>
        <p className="py-2">
          Download each sheet by clicking{' '}
          <strong>
            File > Download > Comma-separated values (.csv, current sheet)
          </strong>{' '}
          in order to use them on Matchmaker.
        </p>
        <p className="py-2">
          Something doesn’t look right? I’d love to hear from you!
          <br />
          <Contact />
        </p>
      </div>

      <h3 className="pt-2 text-xl">
        What if there are more artists than writers?
      </h3>
      <div className="pl-4">
        <p className="py-2">
          Matchmaker has the ability to do multiple rounds of matching in order
          to ensure that all artists will be matched with a writer (so some
          writers will be writing for multiple artists).
        </p>
        <p className="py-2">
          If you'd like, you can also turn that option off and only do one round
          of matching. These will leave options unmatched for mods to match
          manually.
        </p>
      </div>

      <h3 className="pt-2 text-xl">
        What if there are more writers than artists?
      </h3>
      <div className="pl-4">
        <p className="py-2">
          Matchmaker has the ability to do multiple rounds of matching in order
          to ensure that all writers will be matched with an artist. For this,
          artists should be given an option to indicate if they'd be willing to
          double up if necessary to ensure everyone can be matched. (This will
          result in some artists drawing for multiple writers.)
        </p>
        <p className="py-2">
          Again, if you don’t want it to do this (maybe some people haven’t
          submitted claims yet, or you want to match with pinch hitters), you
          can turn that option off and only do one round of matching. These will
          leave options unmatched for mods to match manually.
        </p>
      </div>

      <h3 className="pt-2 text-xl">What if people are late to claims?</h3>
      <div className="pl-4">
        <p className="py-2">
          It’s up to the mods! You could do a round of matching with everyone
          you have and do a second round for what’s left with latecomers (this
          is very much like what happens in first come first serve). You could
          also consider them dropped and double up others. It’s your choice!
          Matchmaker is just a tool; the mods define the process.
        </p>
      </div>

      <h3 className="pt-2 text-xl">
        Can't people game the system by only ranking one choice/putting
        everything as do not want/etc?
      </h3>
      <div className="pl-4">
        <p className="py-2">
          Matchmaker only does matching; it does not check data for validity.
          It's up to the mods to make sure everyone's submissions look good.
        </p>
      </div>

      <h3 className="pt-2 text-xl">
        I don’t want to ask people to rank 30 choices.
      </h3>
      <div className="pl-4">
        <p className="py-2">
          Ranking more choices just gives a buffer in case a lot of people have
          the same top few! It will generally be true that the more choices
          people rank, the smoother matching will go.
        </p>
        <p className="py-2">
          If people have a diverse spread of top choices, most people will get
          something in their top 3. If people think their choices will be very
          popular, it’s best to rank a few more to be safe.
        </p>
      </div>

      <h3 className="pt-2 text-xl">
        Can people put spaces between commas in the top choices/do not want
        lists?
      </h3>
      <p className="pl-4 py-2">The system handles spaces fine! :)</p>

      <h3 className="pt-2 text-xl">How does the actual matching work?</h3>
      <div className="pl-4">
        <p className="py-2">
          <strong>Simple answer:</strong> The matcher tries to give as many
          people their highest ranked choices as possible. If participants are
          diverse in their top choices, most people will end up with a choice in
          their Top 3.
        </p>
        <p className="py-2">
          <strong>Technical answer:</strong> The matcher represents the problem
          as a bipartite graph where the rankings are edges between nodes and
          “do not want” is represented as a missing edge. It is then treated as
          a minimum cost flow problem.
        </p>
      </div>

      <h3 className="pt-2 text-xl">I don’t like (x) / Can this do (x)?</h3>
      <div className="pl-4">
        <p className="py-2">
          I want to make this work for you! Please get in touch with questions
          or suggestions!
          <br />
          <Contact />
        </p>
      </div>

      <h2 className="pt-4 text-2xl">Concerns</h2>

      <h3 className="pt-2 text-xl">
        I don’t want people to get matched with something they don’t want to
        create for.
      </h3>
      <div className="pl-4">
        <p className="py-2">
          If someone has particular works or people they don't want to be
          matched with, make sure it's marked as a Do Not Want, and they will
          never under any circumstances be matched with it. They also have the
          option of choosing not to be matched with anything that they didn't
          rank as a top choice.
        </p>

        <p className="py-2">
          In general, make sure they write down all the ones they’re interested
          in working with as top choices! The more pieces people are willing to
          work with, the easier it is to make sure everyone is matched with
          something they're happy with.
        </p>

        <p className="py-2">
          (This is also a problem in the existing first come, first serve model;
          if you’re late to the form, you may end up with something you don’t
          want, just because it’s what’s left.)
        </p>
      </div>

      <h3 className="pt-2 text-xl">
        I don’t want to leave the claims form open for a long time.
      </h3>
      <div className="pl-4">
        <p className="py-2">
          The current process is to share the summaries/art in advance, then
          open claims a few days later. This process doesn’t have to take any
          longer!
        </p>

        <p className="py-2">
          The claims form can be open for ranking the same time the
          summaries/art are shared, and closed for matching within the next 1-3
          days.
        </p>
      </div>

      <h3 className="pt-2 text-xl">
        I don’t want popular writers always matching with popular artists.
      </h3>
      <p className="pl-4 py-2">
        The matcher gives everyone a fair chance at their top choice, regardless
        of who they are. If 10 people have the same #1 choice, the person who
        ends up getting matched will be random. It has nothing to do with who
        they are, or when they submitted the form.
      </p>

      <h3 className="pt-2 text-xl">Why this and not first-come/first-serve?</h3>
      <div className="pl-4">
        <p className="py-2">
          The first-come, first-serve approach has been useful as a way to
          prevent mods from having to do this kind of matching work, but there
          are some key drawbacks:
        </p>
        <ul className="list-disc list-inside">
          <li>
            Being available when claims open is not easy for everyone—it is not
            inclusive of all timezones, and does not take into account people's
            work, school, and life schedules
          </li>
          <li>
            It’s very luck-based in terms of who submits first—if you’re late,
            everything you’re interested in might be gone because they were all
            someone else’s first choice
          </li>
          <li>
            Sometimes, the process is public to show people what choices are
            left—which means people can see how popular or not their entries are
          </li>
          <li>
            People have found ways around the time zone issue—for example,
            asking someone else to submit a claim for them since they’ll be
            asleep—but not everyone is comfortable with that
          </li>
        </ul>

        <p className="py-2">This matcher</p>
        <ul className="list-disc list-inside">
          <li>
            Gives everyone a chance at their top choices, regardless of who
            submits the form first
          </li>
          <li>
            Does all rounds of matching at once, so no writer sees live how
            popular or not their summaries are
          </li>
        </ul>
      </div>

      <p className="py-2">
        <strong>Is there something I’m missing?</strong> I’d love to hear from
        you!
        <br />
        <Contact />
      </p>
    </Modal>
  )
}

export default FAQModal
