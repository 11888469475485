import React, { useState } from 'react'
import classNames from 'classnames'
import { isUndefined, keys } from 'lodash'

import { Button, Checkbox, Form, Select } from 'antd'
import { ArrowLeft } from 'react-feather'
import UploadButton from 'src/components/UploadButton'
import swal from '@sweetalert/with-react'

import Papa from 'papaparse'
import MatchmakerStore from 'src/stores/MatchmakerStore'
import WizardStore from 'src/stores/WizardStore'

interface PapaParseResults {
  data: Record<string, any>[]
  meta: {
    fields: string[]
  }
}

const UploadOptionsView: React.SFC<{}> = () => {
  // parsed data
  const [optionsData, setOptionsData] = useState<
    Record<string, any>[] | undefined
  >(undefined)
  const [optionsFields, setOptionsFields] = useState<string[] | undefined>(
    undefined
  )

  // selected data
  const [idField, setIdField] = useState<string | undefined>(undefined)
  const [columns, setColumns] = useState<string[] | undefined>(undefined)

  const resetUpload = () => {
    setOptionsData(undefined)
    setOptionsFields(undefined)
    setIdField(undefined)
    setColumns(undefined)
  }

  const onUpload = (file: File) => {
    const data = Papa.parse(file, {
      header: true,
      skipEmptyLines: 'greedy',
      complete: (results: PapaParseResults, file: File) => {
        selectIdField(results.meta.fields[0])
        setOptionsData(results.data)
        setOptionsFields(results.meta.fields)
      },
      error: (error: Error, file: File) => {
        console.log(error)

        swal({
          icon: 'error',
          text: `Error processing the file! Please ensure your file is a CSV.\n${error}`,
        })
      },
    })
  }

  const selectIdField = (field: string) => {
    const oldField = idField
    setIdField(field)

    if (isUndefined(columns)) {
      setColumns([field])
    } else if (!columns.includes(field)) {
      const newColumns = columns.filter((column) => column !== oldField)
      setColumns([...newColumns, field])
    }
    // otherwise columns exist and already includes fields
  }

  const saveOptions = () => {
    // save config and data to matchmaker store
    MatchmakerStore.setOptionIdColumn(idField!)
    MatchmakerStore.setOptionColumns(
      columns!.sort((a: string, b: string) => {
        const aIndex = optionsFields!.indexOf(a)
        const bIndex = optionsFields!.indexOf(b)

        return aIndex < bIndex ? -1 : aIndex === bIndex ? 0 : 1
      })
    )
    MatchmakerStore.setOptionsData(
      optionsData!.map((option) => ({
        id: option[idField!],
        meta: option,
      }))
    )

    // move on with the wizard
    WizardStore.stepNext()
  }

  return isUndefined(optionsData) || isUndefined(optionsFields) ? (
    <>
      <p>
        Upload a CSV of the <strong>options</strong> participants can choose
        from. (These would be the writers in a traditional Big Bang.)
      </p>
      <p>
        <strong>This must be a CSV file with headers.</strong>
      </p>
      <p className="py-2">
        (If using Google Sheets, you can download each of your sheets by
        clicking{' '}
        <strong>
          File > Download > Comma-separated values (.csv, current sheet)
        </strong>{' '}
        to use them on matchmaker.)
      </p>

      <UploadButton onUpload={onUpload} />
    </>
  ) : (
    <>
      <Button onClick={resetUpload}>← Upload a different file</Button>

      <Form className="mt-8" layout="vertical">
        <Form.Item label="Which column do participants use to rank their choices?">
          <Select value={idField} onChange={selectIdField}>
            {optionsFields.map((field) => (
              <Select.Option key={field} value={field}>
                {field}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Which other columns would you like included in the output?">
          <Checkbox.Group
            value={columns}
            onChange={(columns: any[]) => setColumns(columns as string[])}
            options={optionsFields.map((option) => ({
              label: option,
              value: option,
              disabled: option === idField,
            }))}
          />
        </Form.Item>
      </Form>

      <Button type="primary" onClick={saveOptions}>
        Ready for claims!
      </Button>
    </>
  )
}

export default UploadOptionsView
