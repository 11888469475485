import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Row, Col } from 'antd'

import Progress from 'src/components/Progress'
import FAQModal from 'src/views/FAQModal'
import WizardStore from 'src/stores/WizardStore'

import 'antd/dist/antd.css'

const Header: React.SFC<{}> = () => (
  <div className="flex-shrink-0 bg-black flex items-center py-2 px-8">
    <div className="text-3xl text-white font-bold tracking-tighter">
      <a className="text-white" href="https://fandom.love">
        fandom<span className="text-teal-600">dot</span>love
      </a>
    </div>
    <div className="ml-8 pt-1 text-lg uppercase text-white">
      Big Bang Matcher
    </div>
  </div>
)

const Footer: React.SFC<{}> = () => (
  <div className="flex-shrink-0 w-full bg-teal-600 text-white text-sm px-8 py-2">
    <div className="flex justify-between">
      <div>
        made with &#9829; by{' '}
        <strong>
          <a className="text-white" href="https://twitter.com/fandomdotlove">
            sarah
          </a>
        </strong>
      </div>

      <div>
        <a href="https://discord.gg/U9VwP9mAU9">Discord</a>
      </div>
    </div>
  </div>
)

const App: React.SFC<{}> = observer(() => {
  const [showFAQ, setShowFAQ] = useState(false)

  return (
    <>
      <Header />

      <div className="m-8 flex-grow">
        <Row>
          <Col span={6}>
            <Progress />
            <Button onClick={() => setShowFAQ(true)}>Open FAQ</Button>
          </Col>

          <Col span={18}>
            <WizardStore.currentStep.component />
          </Col>
        </Row>

        <FAQModal visible={showFAQ} onClose={() => setShowFAQ(false)} />
      </div>

      <Footer />
    </>
  )
})

export default App
