import React, { useEffect, useState } from 'react'
import { has, isEmpty, isUndefined, sortBy } from 'lodash'
import { observer } from 'mobx-react'

import { Button, Col, Row, Spin, Statistic, Table } from 'antd'
import swal from '@sweetalert/with-react'

import MatchmakerStore from 'src/stores/MatchmakerStore'
import WizardStore from 'src/stores/WizardStore'

export function downloadFile(filename: string, data: string): void {
  const link = document.createElement('a')
  const blob = new Blob(['\ufeff', data])
  const url = URL.createObjectURL(blob)
  link.href = url
  link.target = '_blank'
  link.download = filename
  document.body.appendChild(link) // Required for FF
  link.click()
  document.body.removeChild(link) // Required for FF
}

const MatchingView: React.SFC<{}> = observer(() => {
  // match on load
  useEffect(() => {
    runMatchmaker()
  }, [])

  const runMatchmaker = async () => {
    await MatchmakerStore.doMatching()

    const numRankings = MatchmakerStore.matches!.reduce(
      (
        rankingCount: Record<any, { key: any; count: number }>,
        match: { ranking: any }
      ) => {
        if (has(rankingCount, match.ranking)) {
          rankingCount[match.ranking]['count'] += 1
        } else {
          rankingCount[match.ranking] = {
            key: match.ranking,
            count: 1,
          }
        }

        return rankingCount
      },
      {}
    )

    swal({
      title: 'Matching complete!',
      icon: 'success',
      content: (
        <div>
          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                title="Unmatched Claimants"
                value={
                  MatchmakerStore.matches!.filter((match) =>
                    isEmpty(match.option)
                  ).length
                }
                suffix={`/ ${MatchmakerStore.claimants.length}`}
              />
            </Col>
            <Col span={12}>
              <Statistic
                title="Unmatched Options"
                value={MatchmakerStore.unmatched!.length}
                suffix={`/ ${MatchmakerStore.options.length}`}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col span={24}>
              <Table
                size="small"
                dataSource={sortBy(numRankings, 'ranking')}
                columns={[
                  {
                    title: 'Choice #',
                    key: 'ranking',
                    dataIndex: 'key',
                  },
                  {
                    title: 'Count',
                    key: 'count',
                    dataIndex: 'count',
                  },
                ]}
                pagination={{ pageSize: 5 }}
              />
            </Col>
          </Row>
        </div>
      ),
    })
  }

  if (isUndefined(MatchmakerStore.matches)) {
    return <Spin tip="Matching..." />
  }

  const claimantColumns = MatchmakerStore.config.claimantColumns.map(
    (column) => ({
      title: column,
      key: `claimant.${column}`,
      render: (record: any) => record.claimant[column],
    })
  )
  const optionColumns = MatchmakerStore.config.optionColumns.map((column) => ({
    title: column,
    key: `option.${column}`,
    render: (record: any) => record.option[column],
  }))
  const columns = [
    ...claimantColumns,
    ...optionColumns,
    { title: 'Choice Number', dataIndex: 'ranking', key: 'ranking' },
  ]

  const downloadResults = () => {
    downloadFile('Match Results.csv', MatchmakerStore.resultsCsv)
  }

  return (
    <>
      <div className="flex items-baseline justify-between">
        <h2 className="text-3xl mb-2">Match Results</h2>
        <Button type="primary" onClick={downloadResults}>
          Download CSV
        </Button>
      </div>

      <Table
        size="small"
        dataSource={MatchmakerStore.matches.map((match, i) => ({
          key: i,
          ...match,
        }))}
        columns={columns}
      />

      {!isEmpty(MatchmakerStore.unmatched) && (
        <>
          <h2 className="text-3xl mb-2">Unmatched Options</h2>
          <Table
            size="small"
            dataSource={MatchmakerStore.unmatched!.map((option, i) => ({
              key: i,
              ...option,
            }))}
            columns={MatchmakerStore.config.optionColumns.map((column) => ({
              title: column,
              key: column,
              dataIndex: column,
            }))}
          />
        </>
      )}

      <Button
        className="mt-4"
        type="primary"
        onClick={() => {
          MatchmakerStore.reset()
          WizardStore.reset()
        }}
      >
        Restart
      </Button>
    </>
  )
})

export default MatchingView
