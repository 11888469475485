import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import App from 'src/App'

import 'src/css/main.css'

Sentry.init({
  dsn: 'https://2c008824dc314300820e969d9736b0a4@sentry.io/1995457',
})

const mountNode = document.getElementById('app')
ReactDOM.render(<App />, mountNode)

// Enable hot module reloading for dev
if (module.hot) {
  module.hot.accept()
}
